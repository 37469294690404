<template>
  <el-container class="container" style="height: 100%;">
    <el-aside class="aside" style="background-color:#222d32" width>
      <div class="logo collapsedLogo" :class="isCollapse ? 'logo-collapse' : ''">
        <router-link to="/" tag="div" class="logo-text" style="font-size: 20px;">
          <div v-if="isCollapse" class="div-login">
            <img src="@/assets/logo.png">
          </div>
          <div v-else> {{ name }} </div>
        </router-link>
      </div>
      <!-- 菜单 -->
      <div class="scrollBar">
        <el-row class="Sidebar-Box">
          <el-menu
            :default-active="$route.path"
            :collapse.sync="isCollapse"
            unique-opened
            router
            class="aside-menu-vertical"
            background-color="#222d32"
            text-color="#fff"
          >
            <MenuItem v-for="menu in menuTree" :key="menu.id" :item="menu" />
          </el-menu>
        </el-row>
      </div>
    </el-aside>
    <el-container>
      <el-header class="header" height="auto">
        <el-row class="navbar">
          <el-col :span="10" class="left-menu">
            <div class="left-menu-item fold-btn" :title="isCollapse ? '展开' : '收起'" @click="onCollapse">
              <i :class=" isCollapse ? 'fa el-icon-s-unfold': 'fa el-icon-s-fold' " />
            </div>
          </el-col>

          <el-col :span="4" class="right-menu">
            <el-dropdown trigger="click">
              <div class="right-menu-item">
                <el-image
                  class="user-avatar el-avatar el-avatar--circle"
                  :src="defaultAvatar"
                  style="height:36px;width:36px;line-height: 36px;"
                >
                  <template #error>
                    <img :src="defaultAvatar">
                  </template>
                </el-image>
                <span>管理员</span>
              </div>
              <template #dropdown>
                <el-dropdown-menu :visible-arrow="false" style="margin-top: 2px;width:160px;">
                  <el-dropdown-item icon="el-icon-setting" @click.native="setting">个人设置</el-dropdown-item>
                  <el-dropdown-item divided icon="el-icon-switch-button" @click.native="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
        </el-row>

      </el-header>
      <el-main class="main" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
        <el-scrollbar>
          <keep-alive>
            <router-view style="overflow:'hidden'" />
          </keep-alive>
        </el-scrollbar>
      </el-main>
      <el-footer class="footer" height="50px">
        <div class="pull-right hidden-xs"><b>Version</b> 1.0.0</div>
        <strong>Copyright &copy; {{ (new Date()).getFullYear() }} <a href="">{{ name }}</a>.</strong>
        All rights reserved.
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuItem from './components/MenuItem.vue'
import { listToTree } from '@/utils/format'
const defaultSetting = require('@/setting.js')
export default {
    components: { MenuItem },
    data() {
        return {
            menuTree: [],
            defaultAvatar: require('@/assets/images/avatar.jpg'),
            isCollapse: false,
            name: defaultSetting.name,
            shortName: defaultSetting.shortName,
            avatar: ''
        }
    },
    computed: {
        ...mapGetters(['menus', 'userInfo'])
    },
    created() {
        if (this.menus && this.menus.length > 0) {
            // eslint-disable-next-line no-undef
            const cloneMenus = _.cloneDeep(this.menus)
            this.menuTree = listToTree(cloneMenus)
        }
        console.log(this.$router)
    },
    methods: {
        onCollapse() {
            this.isCollapse = !this.isCollapse
        },
        setting() {
            this.$router.push('/personal/updatePwd')
        },
        logout() {
            var _that = this
            _that.$confirm('确定注销并退出系统吗？', '系统提示', {})
                .then(() => {
                    _that.$store.dispatch('user/loginOut').then(() => {
                        location.reload()
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.container ::v-deep .el-tabs__item:focus.is-active.is-focus:not(:active) {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: unset;
    overflow: hidden;
}

.aside{
    background-color: $theme;
}

/*隐藏文字*/
::v-deep .el-menu--collapse  span {
    display: none;
}

.header {
    z-index: 1;
}

.footer {
    background: #fff;
    padding: 15px;
    color: #444;
    font-size: 14px;
    border-top: 1px solid #d2d6de;
    transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    z-index: 820;
}

.el-breadcrumb {
    line-height: 50px !important;
}

.tabs-warp{
    margin-top: 8px;
}
.tabs-title{
    height: 50px;
    line-height: 50px;
    float: left;
    margin-left: 15px;
}
</style>

<style scoped>
.user-avatar {
    margin: 7px 8px 7px 0;
    vertical-align: top;
    background-color: transparent;
    height:36px;width:36px;line-height: 36px;
}
</style>

<style>
@media screen and (max-width: 680px) {
    .collapsedLogo {
        display: none;
    }

    .el-dialog {
        width: 90% !important;
    }

    .content-expanded {
        max-width: 100% !important;
        max-height: calc(100% - 80px);
    }

    .el-menu--collapse {
        width: 100% !important;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner,
    .el-cascader.el-cascader--medium {
        width: 100% !important;
    }

    .el-message-box {
        width: 80%;
    }
}
</style>
