<!-- 左侧菜单 -->
<template>
  <div class="left-menu">
    <el-submenu v-if="item.children && item.children.length > 0" :index="item.name">
      <template slot="title">
        <i v-if="item.icon" :class="item.icon" />
        <span slot="title">{{ item.title }}</span>
      </template>
      <MenuItem v-for="child in item.children" :key="child.path" :item="child" />
    </el-submenu>
    <template v-else>
      <router-link :to="item.path" tag="div">
        <el-menu-item :index="item.name">
          <i v-if="item.icon" :class="item.icon" />
          <span>{{ item.title }}</span>
        </el-menu-item>
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang='scss' scoped>
.left-menu{
    .el-menu-item{
        font-size: 14px;
    }
    .router-link-active .el-menu-item{
        color: #ffd04b !important;
    }
}
</style>
